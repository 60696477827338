import { ActivatedRoute, Router } from '@angular/router';


export class RouteHelper {

  public static isInNewState(route: ActivatedRoute): boolean {
    return route.snapshot.url.toString().endsWith('new');
  }

  public static async navigateUp(route: ActivatedRoute, router: Router) {
    await router.navigate(['../'], { relativeTo: route });
  }

  public static async navigateToNew(route: ActivatedRoute, router: Router) {
    await router.navigate(['new'], { relativeTo: route });
  }

}
