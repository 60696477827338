<div class="container mb-5">


  <div class="row">
    <div class="col-2">
      <button mat-raised-button (click)="reCreateDatabaseModal()">
        Re-create database
      </button>
    </div>
    <!--        <div class="col-2">-->
    <!--          <button mat-raised-button (click)="copyDemoData()">-->
    <!--            Copy demo data-->
    <!--          </button>-->
    <!--        </div>-->
  </div>

  <div class="row mb-4">
    <div class="col-1">
      <button mat-raised-button (click)="onCancel()">
        Cancel
      </button>
    </div>
  </div>

</div>
