<div class="container">
  <p>Database: <strong>{{ databaseName() }}</strong></p>

  <mat-tab-group dynamicHeight>

    <mat-tab label="Detail">
      <wim-database-detail/>
    </mat-tab>

    <mat-tab label="Actions">
      <wim-database-detail-actions/>
    </mat-tab>

  </mat-tab-group>

</div>

