import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {CantaaMessageService} from '../../../../service/cantaa-message.service';
import {CantaaErrorHandlerService} from '../../../../service/cantaa-error-handler.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteHelper} from '../../../../service/helper/route-helper';
import {KeycloakOrganizationService} from '../../../../service/keycloak-organization.service';
import {KeycloakOrganization} from '../../../../model/keycloak-organization.model';

@Component({
  selector: 'wim-keycloak-organization-detail',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatButton,
    MatCheckbox
  ],
  templateUrl: './keycloak-organization-detail.component.html',
  styleUrl: './keycloak-organization-detail.component.scss'
})
export class KeycloakOrganizationDetailComponent implements OnInit {

  private fb = inject(FormBuilder);
  private messageService = inject(CantaaMessageService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private keycloakOrganizationService = inject(KeycloakOrganizationService);

  detailForm = this.fb.group({
    keycloakId: (''),
    name: ['', Validators.required],
    domains: ['', Validators.required],
    tenant: ['', Validators.required],
    enabled: false,
  });

  async ngOnInit() {
    if (RouteHelper.isInNewState(this.route)) {
      console.log('is in new state');
      await this.initFormForNewEntry();
      return;
    }
    await this.initFormForEdit();
  }

  private async initFormForNewEntry() {
  }

  private async initFormForEdit() {
    const keycloakOrganizationId = this.route.snapshot.paramMap.get('keycloakOrganizationId');
    const detail = await this.fetchKeycloakOrganization(keycloakOrganizationId!);

    this.detailForm.setValue(
      {
        keycloakId: detail.keycloakId,
        name: detail.name,
        tenant: detail.tenant,
        domains: detail.domains,
        enabled: detail.enabled,
      });
  }

  private async fetchKeycloakOrganization(keycloakOrganizationId: string) {
    try {
      return await this.keycloakOrganizationService.getKeycloakOrganization(keycloakOrganizationId);
    } catch (e) {
      this.errorHandler.handleError(e, 'Failed to fetch keycloak organization');
      throw e;
    }
  }

  async onSave() {
    const organization = {
      keycloakId: this.detailForm.getRawValue().keycloakId,
      name: this.detailForm.getRawValue().name,
      domains: this.detailForm.getRawValue().domains,
      tenant: this.detailForm.value.tenant,
      enabled: this.detailForm.value.enabled,
    } as KeycloakOrganization

    try {
      await this.keycloakOrganizationService.save(organization);

      this.messageService.success('Saved');
      await RouteHelper.navigateUp(this.route, this.router);
      // if (RouteHelper.isInNewState(this.route)) {
      //   await RouteHelper.navigateUp(this.route, this.router);
      // }
    } catch (e) {
      this.errorHandler.handleError(e, 'Save failed');
    }
  }

  async onCancel() {
    await RouteHelper.navigateUp(this.route, this.router);
  }

}
