<div class="container">

  <div class="row">
    <div class="col-2">
      <mat-form-field class="w-100">
        <mat-label>Owner</mat-label>
        <mat-select [(ngModel)]="databaseOwner">
          @for (user of databaseUsers(); track user.name) {
            <mat-option [value]="user.name">{{ user.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-1">
      <button mat-flat-button (click)="onSave()">
        Save
      </button>
    </div>
    <div class="col-1">
      <button mat-raised-button (click)="onCancel()">
        Cancel
      </button>
    </div>
  </div>
</div>

