
<mat-selection-list #selectionList >
  @for (role of roles(); track role.name) {
    <mat-list-option [value]="role" [selected]="role.assigned">{{role.name}}</mat-list-option>
  }
</mat-selection-list>

<div class="row">
  <div class="col-3 mb-3">
    <button type="button" mat-raised-button color="primary" (click)="onSave()">
      Save
    </button>
    <button type="button" mat-raised-button class="" (click)="onCancel()">
      Cancel
    </button>
  </div>
</div>
