import {Component, inject, OnInit, signal} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CantaaMessageService } from '../../../../service/cantaa-message.service';
import { CantaaErrorHandlerService } from '../../../../service/cantaa-error-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { JsonPipe } from '@angular/common';
import { KeycloakUser } from '../../../../model/keycloak-user.model';
import { RouteHelper } from '../../../../service/helper/route-helper';
import { KeycloakUserService } from '../../../../service/keycloak-user.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { KeycloakOrganization } from '../../../../model/keycloak-organization.model';
import { KeycloakOrganizationService } from '../../../../service/keycloak-organization.service';

@Component({
  selector: 'wim-keycloak-user-detail',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelect,
    MatOption,
    MatInput,
    MatButton,
    JsonPipe,
    MatCheckbox
  ],
  templateUrl: './keycloak-user-detail.component.html',
  styleUrl: './keycloak-user-detail.component.scss'
})
export class KeycloakUserDetailComponent implements OnInit {
  readonly fb = inject(FormBuilder);
  readonly messageService = inject(CantaaMessageService);
  readonly errorHandler = inject(CantaaErrorHandlerService);
  readonly route = inject(ActivatedRoute);
  readonly router = inject(Router);
  readonly keycloakUserService = inject(KeycloakUserService);
  readonly keycloakOrganizationService = inject(KeycloakOrganizationService);

  organizations = signal<KeycloakOrganization[]>([]);

  detailForm = this.fb.group({
    keycloakId: (''),
    username: ['', Validators.required],
    firstName: (''),
    lastName: (''),
    email: (''),
    organization: this.fb.control<KeycloakOrganization | null>(null),
    enabled: false,
    password: (''),
  });

  async ngOnInit() {
    await this.fetchOrganizations();

    if (RouteHelper.isInNewState(this.route)) {
      await this.initFormForNewEntry();
      return;
    }
    await this.initFormForEdit();
  }

  async fetchOrganizations() {
    try {
      const keycloakOrganization = await this.keycloakOrganizationService.getKeycloakOrganizations();
      this.organizations.set(keycloakOrganization);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch organizations');
      throw e;
    }
  }

  private async initFormForNewEntry() {
  }

  private async initFormForEdit() {
    const userKeycloakId = this.route.snapshot.paramMap.get('userKeycloakId');
    const detail = await this.fetchKeycloakUser(userKeycloakId!);

    const organization = this.organizations().find(o => o.keycloakId === detail.keycloakOrganizationId);

    this.detailForm.controls.username.disable();

    this.detailForm.setValue(
      {
        keycloakId: detail.keycloakId,
        username: detail.username,
        firstName: detail.firstName,
        lastName: detail.lastName,
        email: detail.email,
        organization: organization ?? null,
        enabled: detail.enabled,
        password: ''
      });
  }

  private async fetchKeycloakUser(userKeycloakId: string) {
    try {
      return await this.keycloakUserService.getKeycloakUser(userKeycloakId);
    } catch (e) {
      this.errorHandler.handleError(e, 'Failed to fetch keycloak user');
      throw e;
    }
  }

  async onSave() {
    const user = {
      keycloakId: this.detailForm.getRawValue().keycloakId,
      username: this.detailForm.getRawValue().username,
      firstName: this.detailForm.value.firstName,
      lastName: this.detailForm.value.lastName,
      email: this.detailForm.value.email,
      enabled: this.detailForm.value.enabled,
      password: this.detailForm.value.password,
      keycloakOrganizationId: this.detailForm.value.organization?.keycloakId,
    } as KeycloakUser

    try {
      await this.keycloakUserService.saveKeycloakUser(user);

      this.messageService.success('Saved');
      // if (RouteHelper.isInNewState(this.route)) {
        await RouteHelper.navigateUp(this.route, this.router);
      // }
    } catch (e) {
      this.errorHandler.handleError(e, 'Save failed');
    }

  }

  async onCancel() {
    await RouteHelper.navigateUp(this.route, this.router);
  }

}
