import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { LoggedUser } from '../model/logged-user.model';


@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {

  private anonymous = {
    username: 'anonymous',
    name: '',
    email: '',
    tenant: 'string',
    defaultLocationId: null,
    authorities: []
  }

  private loggedUserSubject$ = new BehaviorSubject<LoggedUser>(this.anonymous);
  public loggedUser$ = this.loggedUserSubject$.asObservable();

  constructor(private http: HttpClient) {
  }

  get loggedUser() {
    return this.loggedUserSubject$.value;
  }

  hasPermission(permission: string): boolean {
    return this.loggedUser?.authorities?.includes(permission);
  }

  hasAnyPermission(permissions: string[]): boolean {
    if (!this.loggedUser) {
      return false;
    }
    return permissions.some(element => {
      // console.log(element + ' el => ' + this.loggedUser.authorities.includes(element));
      return this.loggedUser.authorities.includes(element);
    });
  }

  // fetchLoggedUser() {
  //   const params = this.httpClientHelper.createHttpParams();
  //   let url = environment.apiUrl + `/logged-user`;
  //   return this.http.get<LoggedUser>(url, { params: params })
  //     .subscribe(loggedUser => {
  //       console.log('fetched logged user from BE:', loggedUser);
  //       this.loggedUserSubject$.next(loggedUser);
  //     });
  // }

  async fetchLoggedUserAsync() {
    const params = new HttpParams();
    let url = environment.apiUrl + `/logged-user`;
    const loggedUser = await firstValueFrom(this.http.get<LoggedUser>(url, { params: params }));
    this.loggedUserSubject$.next(loggedUser);
    console.log('fetched logged user from BE:', loggedUser);
  }

}
