import {Component} from '@angular/core';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {KeycloakUserDetailComponent} from '../keycloak-user-detail/keycloak-user-detail.component';
import {KeycloakUserRolesComponent} from '../keycloak-user-roles/keycloak-user-roles.component';

@Component({
  selector: 'wim-keycloak-user-tab-detail',
  standalone: true,
  imports: [
    MatTab,
    MatTabGroup,
    KeycloakUserDetailComponent,
    KeycloakUserRolesComponent
  ],
  templateUrl: './keycloak-user-tab-detail.component.html',
  styleUrl: './keycloak-user-tab-detail.component.scss'
})
export class KeycloakUserTabDetailComponent {

}
