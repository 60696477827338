import { Component, OnInit, signal } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { CantaaErrorHandlerService } from '../../../../service/cantaa-error-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../database/confirm-dialog/confirm-dialog.component';
import { RouteHelper } from '../../../../service/helper/route-helper';
import { KeycloakOrganizationService } from '../../../../service/keycloak-organization.service';
import { KeycloakOrganization } from '../../../../model/keycloak-organization.model';

@Component({
  selector: 'wim-keycloak-organization-list',
  standalone: true,
  imports: [
    MatButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef
  ],
  templateUrl: './keycloak-organization-list.component.html',
  styleUrl: './keycloak-organization-list.component.scss'
})
export class KeycloakOrganizationListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'tenant', 'action'];
  keycloakOrganizations = signal<KeycloakOrganization[]>([]);

  constructor(private keycloakOrganizationService: KeycloakOrganizationService,
              private errorHandler: CantaaErrorHandlerService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog) {
  }

  async ngOnInit() {
    await this.fetchKeycloakOrganizations();
  }

  async fetchKeycloakOrganizations() {
    try {
      const organizations = await this.keycloakOrganizationService.getKeycloakOrganizations();
      this.keycloakOrganizations.set(organizations);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch keycloak users');
      throw e;
    }
  }

  async onEdit(organization: KeycloakOrganization) {
    await this.router.navigate([organization.keycloakId], { relativeTo: this.activatedRoute });
  }

  async onDelete(organization: KeycloakOrganization) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { question: 'Are you sure to delete ' + organization.name + ' ?' },
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.deleteKeycloakOrganization(organization.keycloakId);
      }
    });
  }

  async deleteKeycloakOrganization(keycloakOrganizationId: string) {
    try {
      await this.keycloakOrganizationService.deleteKeycloakOrganization(keycloakOrganizationId);
      await this.fetchKeycloakOrganizations();
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to delete organization');
    }
  }

  async onCreate() {
    await RouteHelper.navigateToNew(this.activatedRoute, this.router);
  }
}
