import { Component, model, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { DatabaseUser } from '../../../model/database-user.model';
import { ConfirmDialogComponent } from '../../database/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseUserService } from '../../../service/database-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { CantaaMessageService } from '../../../service/cantaa-message.service';

@Component({
  selector: 'wim-database-user-detail',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatButton
  ],
  templateUrl: './database-user-detail.component.html',
  styleUrl: './database-user-detail.component.scss'
})
export class DatabaseUserDetailComponent implements OnInit {

  userName = signal('');
  databaseUserPassword = model<string>('');

  constructor(private dialog: MatDialog,
              private databaseUserService: DatabaseUserService,
              private errorHandler: CantaaErrorHandlerService,
              private router: Router,
              private messageService: CantaaMessageService,
              private activatedRoute: ActivatedRoute) {
  }

  async ngOnInit() {
    this.userName.set(this.activatedRoute.snapshot.paramMap.get('userName')!);
  }

  async onUpdatePassword() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { question: 'Are you sure to update password for ' + this.userName() + ' ?' },
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.updatePassword(this.userName());
      }
    });
  }

  async updatePassword(userName: string) {
    try {
      console.log('user password changed', this.databaseUserPassword());

      const user = {
        name: this.userName(),
        password: this.databaseUserPassword()
      };
      await this.databaseUserService.updateDatabaseUserPassword(user);
      this.messageService.success("Password updated");
      this.databaseUserPassword.set('');
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to delete database user');
    }
  }

  async onCancel() {
    await this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

}
