<mat-toolbar class="toolbar">
  <button mat-icon-button class="" aria-label="burger menu"
  (click)="toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span>WIM Admin console</span>

  <span class="spacer"></span>

  &nbsp;
  <button mat-button routerLink="logout" matTooltip="Log out">
    <mat-icon>logout</mat-icon>
    {{ userName }}
  </button>
  &nbsp;

</mat-toolbar>
