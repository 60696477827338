<div class="container " style="min-height: 80vh">

  <div class="row">
    <div class="col-6">
      <mat-form-field class="w-100">
        <mat-label>Organization</mat-label>
        <mat-select [(value)]="organizationKeycloakId" (selectionChange)="onOrganizationChange()">
          <mat-option [value]="'allOrganizations'">-- All users --</mat-option>
          <mat-option [value]="'withoutOrganizations'">-- Without organizations --</mat-option>
          @for (organization of organizations(); track organization.keycloakId) {
            <mat-option [value]="organization.keycloakId">{{ organization.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

    </div>
  </div>

  <button class="mb-3" mat-raised-button (click)="onCreate()">
    Create user
    <mat-icon>add_circle</mat-icon>
  </button>


  <table mat-table [dataSource]="keycloakUsers()" class="mat-elevation-z8">

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>User name</th>
      <td mat-cell *matCellDef="let element"> {{ element.username }}</td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>First name</th>
      <td mat-cell *matCellDef="let element"> {{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Last name</th>
      <td mat-cell *matCellDef="let element"> {{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element"> {{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th class="" mat-header-cell *matHeaderCellDef></th>
      <td class="" mat-cell *matCellDef="let element">
        <button mat-icon-button type="button" class="" title="Edit user" (click)="onEdit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button type="button" class="" title="Delete user" (click)="onDelete(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>


