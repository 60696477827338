<div class="container " style="min-height: 80vh">
  <table mat-table [dataSource]="databases()" class="mat-elevation-z8">

    <ng-container matColumnDef="database">
      <th mat-header-cell *matHeaderCellDef>Database name</th>
      <td mat-cell *matCellDef="let element"> {{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th class="" mat-header-cell *matHeaderCellDef></th>
      <td class="" mat-cell *matCellDef="let element">
        <button mat-icon-button type="button" class="" title="Edit database" (click)="onEdit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button type="button" class="" title="Delete database" (click)="onDelete(element)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button type="button" class="" title="Rename database" (click)="onRename(element)">
          <mat-icon>border_color</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <button class="mt-3" mat-raised-button (click)="openCreateDialog()">
    Create database
    <mat-icon>add_circle</mat-icon>
  </button>

</div>


