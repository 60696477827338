import {inject, Injectable} from '@angular/core';
import {AbstractControl, AsyncValidator, ValidationErrors} from '@angular/forms';
import {catchError, map, Observable, of} from 'rxjs';
import {KeycloakOrganizationService} from "../service/keycloak-organization.service";

@Injectable({providedIn: 'root'})
export class UniqueKeycloakOrganizationValidator implements AsyncValidator {

  keycloakOrganizationService = inject(KeycloakOrganizationService);

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.keycloakOrganizationService.isOrganizationTaken(control.value)
      .pipe(
        map((isTaken) => (isTaken ? {uniqueOrganization: true} : null)
        ),
        catchError(() => of(null)),
      );
  }

}
