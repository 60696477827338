<!-- https://fonts.google.com/icons -->

<mat-nav-list>

  <mat-divider/>
  <a mat-list-item routerLink="dashboard">
    <mat-icon matListItemIcon>dashboard</mat-icon>
    <span routerLinkActive="active">Dashboard</span>
  </a>

  <a mat-list-item routerLink="create-tenant">
    <mat-icon matListItemIcon>groups</mat-icon>
    <span routerLinkActive="active">Setup new organization</span>
  </a>

  <a mat-list-item routerLink="guide">
    <mat-icon matListItemIcon>psychology_alt</mat-icon>
    <span routerLinkActive="active">Guide</span>
  </a>

  <mat-divider/>

  <a mat-list-item routerLink="databases">
    <mat-icon matListItemIcon>storage</mat-icon>
    <span routerLinkActive="active">Databases</span>
  </a>

  <a mat-list-item routerLink="database-users">
    <mat-icon matListItemIcon>person</mat-icon>
    <span routerLinkActive="active">Database users</span>
  </a>

  <mat-divider/>

  <a mat-list-item routerLink="keycloak-organizations">
    <mat-icon matListItemIcon>supervisor_account</mat-icon>
    <span routerLinkActive="active">Organizations</span>
  </a>

  <a mat-list-item routerLink="keycloak-users">
    <mat-icon matListItemIcon>manage-accounts</mat-icon>
    <span routerLinkActive="active">Keycloak users</span>
  </a>

  <mat-divider/>

  <a mat-list-item routerLink="about">
    <mat-icon matListItemIcon>info</mat-icon>
    <span routerLinkActive="active">About</span>
  </a>

</mat-nav-list>


