import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OAuthService, provideOAuthClient } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { oauthInit } from './initializer/oauth.init';
import { LoggedUserService } from './service/logged-user.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideOAuthClient({
        resourceServer: {
          allowedUrls: [environment.apiUrl],
          sendAccessToken: true
        }
      }
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: oauthInit,
      multi: true,
      deps: [OAuthService, LoggedUserService],
    },
  ]
};
