import { Injectable } from '@angular/core';
import { SnackbarService } from './snackbar.service';
// import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class CantaaMessageService {

  // constructor(private messageService: MessageService) {
  constructor(private snackbarService: SnackbarService) {
  }

  public success(summaryKey: string) {
    // this.messageService.add({ severity: 'success', summary: summaryKey, detail: undefined })
    // this.snackbarService.openSuccessSnackBar(summaryKey, 'detail');
    this.snackbarService.openSuccessSnackBar(summaryKey);
  }

  public error(summaryKey: string, detailKey?: string) {
    const summary = summaryKey;
    const detail = detailKey;
    // this.messageService.add({ severity: 'error', summary: summary, detail: detail, life: 10000 })
    this.snackbarService.openErrorSnackBar(summaryKey, detail);
  }

}
