import { Component, OnInit } from '@angular/core';
import { StageInfo } from '../../../model/stage-detail.model';
import { StageInfoService } from '../../../service/stage-info.service';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'wim-dashboard',
  standalone: true,
  imports: [
    MatTableModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  protected readonly JSON = JSON;
  stages: StageInfo[] = [];
  displayedColumns: string[] = ['name', 'url', 'version'];

  constructor(private stageInfoService: StageInfoService) {
  }

  async ngOnInit() {
    this.stages = this.stageInfoService.stages;
    console.log(this.stages);
    this.fetchData();
  }

  async fetchData() {
    this.stages = await this.stageInfoService.getStageDetails();
  }

}
