import { Component, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { SnackbarData } from './snackbar-data';

@Component({
  selector: 'wim-cantaa-snack-bar',
  standalone: true,
  imports: [
    MatSnackBarLabel,
    MatSnackBarActions,
    MatSnackBarAction,
    MatButton
  ],
  templateUrl: './cantaa-snack-bar.component.html',
  styleUrl: './cantaa-snack-bar.component.scss'
})
export class CantaaSnackBarComponent {
  snackbarData: SnackbarData;

  snackBarRef = inject(MatSnackBarRef);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {
    this.snackbarData = data;
  }
}
