import {Component, inject, model} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {CantaaErrorHandlerService} from "../../../service/cantaa-error-handler.service";
import {KeycloakOrganizationService} from "../../../service/keycloak-organization.service";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {KeycloakOrganization} from "../../../model/keycloak-organization.model";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatCardModule} from "@angular/material/card";
import {DatabaseUserService} from "../../../service/database-user.service";
import {DatabaseUser} from "../../../model/database-user.model";
import {DatabaseService} from "../../../service/database.service";
import {KeycloakUser} from "../../../model/keycloak-user.model";
import {KeycloakUserService} from "../../../service/keycloak-user.service";
import {Clipboard} from "@angular/cdk/clipboard";
import {MatIconModule} from "@angular/material/icon";
import {UniqueKeycloakUsernameValidator} from "../../../validator/unique-keycloak-username.validator";
import {UniqueKeycloakEmailValidator} from "../../../validator/unique-keycloak-email.validator";
import {UniqueKeycloakOrganizationValidator} from "../../../validator/unique-keycloak-organization.validator";

@Component({
  selector: 'wim-create-tenant',
  standalone: true,
  imports: [
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatOption,
    MatSelect,
    MatCardModule,
    FormsModule,
    MatIconModule,
    MatIconButton,
    MatError
  ],
  templateUrl: './setup-organization.component.html',
  styleUrl: './setup-organization.component.scss'
})
export class SetupOrganizationComponent {

  private fb = inject(FormBuilder);
  private errorHandler = inject(CantaaErrorHandlerService);
  private keycloakOrganizationService = inject(KeycloakOrganizationService);
  readonly databaseUserService = inject(DatabaseUserService);
  readonly databaseService = inject(DatabaseService);
  readonly keycloakUserService = inject(KeycloakUserService);
  readonly clipboard = inject(Clipboard);
  readonly uniqueKeycloakUsernameValidator = inject(UniqueKeycloakUsernameValidator);
  readonly uniqueKeycloakEmailValidator = inject(UniqueKeycloakEmailValidator);
  readonly uniqueKeycloakOrganizationValidator = inject(UniqueKeycloakOrganizationValidator);

  stage = model<string | null>('main');
  statusText = '';

  detailForm = this.fb.group({
    organizationName: ['', Validators.required],
    organizationDomains: ['', Validators.required],
    organizationId: ['', {
      validators: [Validators.required],
      asyncValidators: [this.uniqueKeycloakOrganizationValidator.validate.bind(this.uniqueKeycloakOrganizationValidator)],
      updateOn: 'blur'
    }],
    username: ['', {
      validators: [Validators.required, Validators.minLength(3)],
      asyncValidators: [this.uniqueKeycloakUsernameValidator.validate.bind(this.uniqueKeycloakUsernameValidator)],
      updateOn: 'blur'
    }],
    password: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', {
      validators: [Validators.required, Validators.email],
      asyncValidators: [this.uniqueKeycloakEmailValidator.validate.bind(this.uniqueKeycloakEmailValidator)],
      updateOn: 'blur'
    }],
  });

  async onSave() {
    this.statusText = '';

    let error = await this.createDatabaseUser();

    if (error) {
      this.addMessageToStatus(error);
      return;
    }
    this.addMessageToStatus('Database user was created');


    error = await this.createDatabase();

    if (error) {
      this.addMessageToStatus(error);
      return;
    }
    this.addMessageToStatus('Database was created');


    const result = await this.createTenant();


    if (result.error) {
      this.addMessageToStatus(result.error);
      return;
    }
    this.addMessageToStatus('Organization was created');


    error = await this.createKeycloakUser(result.tenantKeycloakId);

    if (error) {
      this.addMessageToStatus(error);
    }
    this.addMessageToStatus('Keycloak user was created');


    this.addMessageToStatus('Finished successfully');

    const tenantId = this.detailForm.value.organizationId;

    this.detailForm.reset(
      {
        // keep organizationId to be able to copy application.properties
        organizationId: tenantId
      }
    );
  }

  async createDatabaseUser() {
    try {
      const user = {
        name: this.getDatabaseUser(),
        password: this.getDatabasePassword()
      } as DatabaseUser;
      await this.databaseUserService.createDatabaseUser(user);
      return null;
    } catch (e) {
      let error = 'Unable to create database user: ' + this.detailForm.value.username;
      return error + this.errorHandler.handleError(e, error);
    }
  }

  async createDatabase() {
    try {
      const database = {
        name: this.getDatabaseName(),
        owner: this.getDatabaseUser(),
      }
      await this.databaseService.createDatabase(database);
      return null;
    } catch (e) {
      let error = 'Unable to create database: ' + this.getDatabaseName();
      return error + this.errorHandler.handleError(e, error);
    }
  }

  async createTenant() {
    const organization = {
      name: this.detailForm.getRawValue().organizationName,
      domains: this.detailForm.getRawValue().organizationDomains,
      tenant: this.detailForm.value.organizationId,
      enabled: true,
    } as KeycloakOrganization

    try {
      let tenantKeycloakId = await this.keycloakOrganizationService.save(organization);
      return {tenantKeycloakId};
    } catch (e) {
      let error = 'Unable to create tenant: ' + organization.tenant + ': ';
      return {
        error: error + this.errorHandler.handleError(e, error),
        tenantKeycloakId: ''
      };
    }
  }

  async createKeycloakUser(tenantKeycloakId: string) {
    const user = {
      username: this.detailForm.getRawValue().username,
      firstName: this.detailForm.value.firstName,
      lastName: this.detailForm.value.lastName,
      email: this.detailForm.value.email,
      enabled: true,
      password: this.detailForm.value.password,
      keycloakOrganizationId: tenantKeycloakId,
    } as KeycloakUser

    try {
      await this.keycloakUserService.saveKeycloakUser(user);
      return null;
    } catch (e) {
      let error = 'Unable to create keycloak user: ' + user.username + ': ';
      return error + this.errorHandler.handleError(e, error);
    }
  }

  async onCancel() {
    this.detailForm.reset();
  }

  getOrganizationName() {
    return this.detailForm.getRawValue().organizationId;
  }

  getDatabaseUser() {
    return 'wim_' + this.stage() + '_' + this.detailForm.getRawValue().organizationId;
  }

  getDatabasePassword() {
    return this.getDatabaseUser();
  }

  getDatabaseName() {
    return 'wim_' + this.stage() + '_' + this.detailForm.getRawValue().organizationId;
  }

  addMessageToStatus(msg: string) {
    this.statusText = this.statusText + '\n' + msg;
  }

  copyText(textElement: HTMLElement) {
    const text = textElement.innerText;
    this.clipboard.copy(text);
  }

}
