import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom, map, tap } from 'rxjs';
import { CollectionDto } from '../model/collection.model';
import { Database } from '../model/database.model';
import { ConsoleLoggerService } from './console-logger.service';
import { DatabaseDetail } from '../model/database-detail.model';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private http: HttpClient, private log: ConsoleLoggerService) {
  }

  getDatabases() {
    const params = new HttpParams();
    const url = environment.apiUrl + `/databases`;
    const databases$ = this.http.get<CollectionDto<Database>>(url, { params: params })
      .pipe(tap(r => this.log.debug('databases: ', r)))
      .pipe(map(c => c.items))
    return firstValueFrom(databases$);
  }

  getDatabaseDetail(databaseName: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/databases/${databaseName}`;
    const databases$ = this.http.get<DatabaseDetail>(url, { params: params })
      .pipe(tap(r => this.log.debug('database detail: ', r)))

    return firstValueFrom(databases$);
  }

  saveDatabaseDetail(databaseDetail: DatabaseDetail) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/databases/${databaseDetail.name}`;
    const request$ = this.http.put<void>(url, databaseDetail, { params: params })

    return firstValueFrom(request$);
  }

  createDatabase(database: Database) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/databases`;
    const database$ = this.http.post<Database>(url, database, { params: params });
    return firstValueFrom(database$);
  }

  reCreateDatabase(databaseName: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/databases/${databaseName}/re-create`;
    const database$ = this.http.post<Database>(url, null, { params: params });
    return firstValueFrom(database$);
  }

  renameDatabase(oldDatabaseName: string, newDatabaseName: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/databases/${oldDatabaseName}/rename/${newDatabaseName}`;
    const database$ = this.http.post<Database>(url, null, { params: params });
    return firstValueFrom(database$);
  }

  deleteDatabase(databaseName: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/databases/${databaseName}`;
    const database$ = this.http.delete<void>(url, { params: params });
    return firstValueFrom(database$);
  }

  async copyDemoData(databaseName: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/databases/${databaseName}/demo-data`;
    const database$ = this.http.get<void>(url, { params: params });
    return firstValueFrom(database$);
  }
}
