import {Component, inject, model} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {DatabaseService} from '../../../service/database.service';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {CantaaMessageService} from '../../../service/cantaa-message.service';

@Component({
  selector: 'wim-change-db-name-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatInput,
    MatLabel
  ],
  templateUrl: './change-db-name-dialog.component.html',
  styleUrl: './change-db-name-dialog.component.scss'
})
export class ChangeDbNameDialogComponent {

  readonly data = inject<{ databaseName: string }>(MAT_DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef<ChangeDbNameDialogComponent>);
  readonly databaseService = inject(DatabaseService);
  readonly errorHandler = inject(CantaaErrorHandlerService);
  readonly messageService = inject(CantaaMessageService);

  readonly newDatabaseName = model(this.data.databaseName);

  async onConfirm() {
    try {
      await this.databaseService.renameDatabase(this.data.databaseName, this.newDatabaseName());
      this.messageService.success("Database renamed");
      this.dialogRef.close(true);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to rename database: ' + this.newDatabaseName());
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  renameDisabled() {
    return !this.newDatabaseName() || this.newDatabaseName().trim().length == 0 || this.newDatabaseName() === this.data.databaseName;
  }
}
