import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoggedUserService } from './service/logged-user.service';
import { environment } from '../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { SidenavItemsComponent } from './components/sidenav-items/sidenav-items.component';
import { MainToolbar } from './components/main-toolbar/main-toolbar.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet,
    MatSidenav,
    MatSidenavContent,
    MatSidenavContainer,
    SidenavItemsComponent,
    MainToolbar]
})
export class AppComponent implements OnInit {
  title = 'wim-admin-fe';


  constructor(private oauthService: OAuthService,
              private loggedUserService: LoggedUserService) {

    console.log('used environment: ' + environment.environment);
  }

  async ngOnInit() {

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(async e => {
        this.oauthService.loadUserProfile().then((userProfile) => {
          console.log('user profile from token_received event', userProfile);
        })
        await this.loggedUserService.fetchLoggedUserAsync();
      });
  }

}
