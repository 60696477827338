import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom, map, tap } from 'rxjs';
import { CollectionDto } from '../model/collection.model';
import { Database } from '../model/database.model';
import { ConsoleLoggerService } from './console-logger.service';
import { DatabaseUser } from '../model/database-user.model';


@Injectable({
  providedIn: 'root'
})
export class DatabaseUserService {

  constructor(private http: HttpClient, private log: ConsoleLoggerService) {
  }

  getDatabaseUsers() {
    const params = new HttpParams();
    const url = environment.apiUrl + `/database-users`;
    const databases$ = this.http.get<CollectionDto<DatabaseUser>>(url, { params: params })
      .pipe(tap(r => this.log.debug('database users: ', r)))
      .pipe(map(c => c.items))
    return firstValueFrom(databases$);
  }

  createDatabaseUser(databaseUser: DatabaseUser) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/database-users`;
    const database$ = this.http.post<DatabaseUser>(url, databaseUser, { params: params });
    return firstValueFrom(database$);
  }

  deleteDatabaseUser(userName: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/database-users/${userName}`;
    const database$ = this.http.delete<void>(url, { params: params });
    return firstValueFrom(database$);
  }

  updateDatabaseUserPassword(databaseUser: DatabaseUser) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/database-users/${databaseUser.name}`;
    const database$ = this.http.put<void>(url,  databaseUser, { params: params });
    return firstValueFrom(database$);
  }

}
