<div class="container p-3">

  <p>Database user: <strong>{{ userName() }}</strong></p>

  <div>
    <mat-form-field>
      <mat-label>New user password</mat-label>
      <input matInput [(ngModel)]="databaseUserPassword"/>
    </mat-form-field>
  </div>

  <div class="row mb-4">
    <div class="col-1">
      <button mat-flat-button (click)="onUpdatePassword()">
        Save
      </button>
    </div>
    <div class="col-1">
      <button mat-raised-button (click)="onCancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
