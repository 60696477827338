import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

const noop = (): any => undefined;

// inspiration from: https://rob-ferguson.me/a-simple-logging-service-for-angular-4/
@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService {

  private readonly TRACE = 0;
  private readonly DEBUG = 1;
  private readonly INFO = 2;
  private readonly WARN = 3;
  private readonly ERROR = 4;

  private logLevel = this.INFO;

  constructor() {
    this.setLogLevelFromConfig();
  }

  get trace() {
    if (this.isLogLevelEnabled(this.TRACE)) {
      return console.trace.bind(console);
    } else {
      return noop;
    }
  }

  get debug() {
    if (this.isLogLevelEnabled(this.DEBUG)) {
      return console.debug.bind(console);
    } else {
      return noop;
    }
  }

  get info() {
    if (this.isLogLevelEnabled(this.INFO)) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (this.isLogLevelEnabled(this.WARN)) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error() {
    if (this.isLogLevelEnabled(this.ERROR)) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }

  private isLogLevelEnabled(logLevel: number): boolean {
    return logLevel >= this.logLevel;
  }

  private setLogLevelFromConfig() {
    let logLevel = environment.logLevel;
    console.log('Log level: ', logLevel);


    if (ConsoleLoggerService.compareIgnoreCase('TRACE', logLevel)) {
      this.logLevel = this.TRACE;
    } else if (ConsoleLoggerService.compareIgnoreCase('DEBUG', logLevel)) {
      this.logLevel = this.DEBUG;
    } else if (ConsoleLoggerService.compareIgnoreCase('INFO', logLevel)) {
      this.logLevel = this.INFO;
    } else if (ConsoleLoggerService.compareIgnoreCase('WARN', logLevel)) {
      this.logLevel = this.WARN;
    } else {
      this.logLevel = this.ERROR;
    }
  }

  private static

  compareIgnoreCase(string1
                      :
                      string, string2
                      :
                      string
  ):
    boolean {
    return string1.toUpperCase() === string2.toUpperCase();
  }

}
