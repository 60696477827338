export class ErrorHelper {

  public static parseError(error: any): string {
    if (error.error?.detail) {
      return error.error.detail;
    }
    if (error.error?.message) {
      return error.error.message;
    }
    if (error.message) {
      return error.message;
    }
    return JSON.stringify(error)
  }

}
