import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { LoggedUserService } from '../service/logged-user.service';

// https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards

export function oauthInit(oauthService: OAuthService, loggedUserService: LoggedUserService) {
  return () => new Promise<void>((resolve) => {
    // https://www.youtube.com/watch?v=QV5YtczsorY
    // https://github.com/tamani-coding/angular-google-oauth-example/blob/main/src/app/google-api.service.ts

    oauthService.configure(environment.authCodeFlowConfig);
    // manually configure a logout url, because googles discovery document does not provide it
    // oAuthService.logoutUrl = "https://www.google.com/accounts/Logout";

    oauthService.setupAutomaticSilentRefresh();

    oauthService.events.subscribe(event => {
      // console.info('oauthService events ---->', event);
    });

    // oauthService.events
    //   .pipe(filter(e => ['token_received'].includes(e.type)))
    //   .subscribe(e => {
    //     oauthService.loadUserProfile().then((userProfile) => {
    //       console.log('user profile from token_received event', userProfile);
    //     })
    //     loggedUserService.fetchLoggedUser();
    //   });

    // loading the discovery document from google, which contains all relevant URL for
    // the OAuth flow, e.g. login url
    oauthService.loadDiscoveryDocument().then(() => {
      // // This method just tries to parse the token(s) within the url when
      // // the auth-server redirects the user back to the web-app
      // // It doesn't send the user the the login page
      // oauthService.tryLoginImplicitFlow().then(() => {
      oauthService.tryLogin().then(() => {

        // when not logged in, redirect to google for login
        // else load user profile
        if (oauthService.hasValidAccessToken()) {
          // oauthService.loadUserProfile().then((userProfile) => {
          //   // console.log('user profile from hasValidAccessToken', userProfile);
          //   // userProfileSubject.next(userProfile as UserInfo)
          //
          //   // loggedUserService.fetchLoggedUser();
          //
          //   resolve();
          // })

          loggedUserService.fetchLoggedUserAsync().then((loggedUser) => {
            console.log('logged user from oauthInit', loggedUser);
            resolve();
          })

        } else {
          oauthService.initCodeFlow()
          // oauthService.initLoginFlow()
        }

      })
    });
  });
}
