<div class="container p-3">
  <form [formGroup]="detailForm" (ngSubmit)="onSave()">

    <div class="row">
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Stage where new organization should be created</mat-label>
          <mat-select [(value)]="stage">
            <mat-option [value]="'develop'">develop</mat-option>
            <mat-option [value]="'main'">main</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>

    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>Organization</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Organization name</mat-label>
              <input matInput formControlName="organizationName">
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Organization id</mat-label>
              <input matInput formControlName="organizationId">
            </mat-form-field>
            @if (detailForm.controls.organizationId.hasError('uniqueOrganization')) {
              <mat-error>
                Organization is already taken.
              </mat-error>
            }
          </div>

          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Organization domains</mat-label>
              <input matInput formControlName="organizationDomains">
            </mat-form-field>
          </div>

        </div>
      </mat-card-content>
    </mat-card>


    <mat-card appearance="outlined" class="mt-4">
      <mat-card-header>
        <mat-card-title>Keycloak user</mat-card-title>
        <mat-card-subtitle>User name must be unique for all organizations</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <div class="row">

          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>User name</mat-label>
              <input matInput formControlName="username">
            </mat-form-field>
            @if (detailForm.controls.username.hasError('uniqueUsername')) {
              <mat-error>
                Username is already taken.
              </mat-error>
            } @else if (detailForm.controls.username.hasError('minlength')) {
              <mat-error>
                Username must be at least 3 characters long.
              </mat-error>
            }
          </div>

          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password">
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email">
            </mat-form-field>
            @if (detailForm.controls.email.hasError('uniqueEmail')) {
              <mat-error>
                Email is already taken.
              </mat-error>
            }
          </div>

        </div>

        <div class="row">
          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>First name</mat-label>
              <input matInput formControlName="firstName">
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field class="w-100">
              <mat-label>Last name</mat-label>
              <input matInput formControlName="lastName">
            </mat-form-field>
          </div>

        </div>

      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="mt-4">
      <mat-card-header>
        <mat-card-title>What will happen?</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <div class="row">
          <div class="col-12">
            <div>New database user will be created: {{ getDatabaseUser() }}</div>
            <div>New database: {{ getDatabaseName() }}</div>
            <div>New organization: {{ detailForm.getRawValue().organizationId }}</div>
            <div>New keycloak user: {{ detailForm.getRawValue().username }}</div>
          </div>
        </div>

      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="mt-4">
      <mat-card-header>
        <mat-card-title>
          Application properties
          <button mat-icon-button (click)="copyText(textToCopy)" title="Copy" type="button">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-card-title>
        <mat-card-subtitle>application-{{ stage() }}.properties</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <div class="row">
          <div class="col-12">
            <p #textToCopy style="white-space: none">
              multitenancy.tenants.{{ getOrganizationName() }}<!--
              -->.datasource.url=jdbc:postgresql://10.1.77.0:5432/{{ getDatabaseName() }}?currentSchema=api<br/>
              multitenancy.tenants.{{ getOrganizationName() }}.datasource.username={{ getDatabaseUser() }}<br/>
              multitenancy.tenants.{{ getOrganizationName() }}.datasource.password={{ getDatabasePassword() }}<br/>
            </p>
          </div>
        </div>

      </mat-card-content>
    </mat-card>


    <div class="row">
      <div class="col-3">
        <button type="submit" mat-raised-button color="primary" [disabled]="detailForm.invalid">
          Create
        </button>
        <button type="button" mat-raised-button class="ms-2" (click)="onCancel()">
          Cancel
        </button>
      </div>
    </div>

  </form>

  <div class="row">
    <div>Progress:</div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-form-field id="statusBox" class="w-100">
        <textarea matInput [(ngModel)]="statusText" disabled rows="7"></textarea>
      </mat-form-field>
    </div>
  </div>

</div>
