import {Component, model, OnInit} from '@angular/core';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {ActivatedRoute} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {DatabaseDetailComponent} from '../database-detail/database-detail.component';
import {DatabaseDetailActionsComponent} from '../database-detail-actions/database-detail-actions.component';

@Component({
  selector: 'wim-database-tab-detail',
  standalone: true,
  imports: [
    FormsModule,
    MatTab,
    MatTabGroup,
    DatabaseDetailComponent,
    DatabaseDetailActionsComponent
  ],
  templateUrl: './database-tab-detail.component.html',
  styleUrl: './database-tab-detail.component.scss'
})
export class DatabaseTabDetailComponent implements OnInit {

  databaseName = model('');

  constructor(private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const databaseName = this.route.snapshot.paramMap.get('databaseName')!;
    console.log(databaseName);
    this.databaseName.set(databaseName)
  }

}
