import {Component, OnInit} from '@angular/core';
import {MatDivider} from '@angular/material/divider';
import {MatIcon} from '@angular/material/icon';
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from '@angular/router';
import {MatListItem, MatListModule} from '@angular/material/list';

@Component({
  selector: 'wim-sidenav-items',
  standalone: true,
  imports: [
    MatDivider,
    MatIcon,
    RouterLink,
    RouterLinkActive,
    MatListItem,
    MatListModule,
  ],
  templateUrl: './sidenav-items.component.html',
  styleUrl: './sidenav-items.component.scss'
})
export class SidenavItemsComponent {

  constructor() {
  }

}
