import { Injectable } from '@angular/core';
import { ErrorHelper } from './helper/error-helper';
import { CantaaMessageService } from './cantaa-message.service';
import { ConsoleLoggerService } from './console-logger.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CantaaErrorHandlerService {

  constructor(private messageService: CantaaMessageService,
              private log: ConsoleLoggerService) {
  }

  public handleError(e: any, errorTitle?: string) {
    this.log.error(e)
    const error = ErrorHelper.parseError(e);
    if (errorTitle) {
      this.messageService.error(errorTitle, error);
    } else {
      this.messageService.error(error);
    }

    return error;
  }


  // https://juri.dev/blog/2019/02/display-server-side-validation-errors-with-angular/
  public handleFormError(form: FormGroup, e: any, errorTitle?: string) {
    if (e.status === 422) {
      let additionalError = '';
      e.error.violations.forEach((v: any) => {
        const formControl = form.get(v.field);
        if (formControl) {
          formControl.setErrors({
            serverError: v.message
          });
        } else {
          additionalError += `\n ${v.field} ${v.message}`;
        }
      });
      if (additionalError.length > 0) {
        this.messageService.error('ERROR.VIOLATION', additionalError);
      }
    } else {
      this.handleFormError(e, errorTitle);
    }
    return ErrorHelper.parseError(e);
  }

}
