import {Component, model, OnInit, signal} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CantaaMessageService} from '../../../service/cantaa-message.service';
import {DatabaseService} from '../../../service/database.service';
import {MatButton} from '@angular/material/button';
import {CantaaErrorHandlerService} from '../../../service/cantaa-error-handler.service';
import {DatabaseDetail} from '../../../model/database-detail.model';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {DatabaseUser} from '../../../model/database-user.model';
import {FormsModule} from '@angular/forms';
import {DatabaseUserService} from '../../../service/database-user.service';

@Component({
  selector: 'wim-database-detail',
  standalone: true,
  imports: [
    MatButton,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    FormsModule
  ],
  templateUrl: './database-detail.component.html',
  styleUrl: './database-detail.component.scss'
})
export class DatabaseDetailComponent implements OnInit {

  databaseDetail = signal<DatabaseDetail | null>(null);
  databaseOwner = model('');
  databaseUsers = signal<DatabaseUser[]>([]);

  constructor(private databaseService: DatabaseService,
              private messageService: CantaaMessageService,
              private router: Router,
              private route: ActivatedRoute,
              private errorHandler: CantaaErrorHandlerService,
              private databaseUserService: DatabaseUserService) {
  }

  async ngOnInit() {
    const databaseName = this.route.snapshot.paramMap.get('databaseName');
    console.log(databaseName);
    await this.fetchDatabaseUsers();

    const databaseDetail = await this.fetchDatabaseDetail(databaseName!);
    this.databaseOwner.set(databaseDetail.owner);
    this.databaseDetail.set(databaseDetail)
  }

  async fetchDatabaseDetail(databaseName: string) {
    try {
      return await this.databaseService.getDatabaseDetail(databaseName);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch database detail');
      throw e;
    }
  }

  async onSave() {
    try {
      const detail = {
        name: this.databaseDetail()!.name,
        owner: this.databaseOwner(),
      }
      await this.databaseService.saveDatabaseDetail(detail);
      this.messageService.success('Database detail saved.');
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to save database detail');
      throw e;
    }
  }

  async fetchDatabaseUsers() {
    try {
      const databaseUsers = await this.databaseUserService.getDatabaseUsers();
      this.databaseUsers.set(databaseUsers);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch database users');
      throw e;
    }
  }

  async onCancel() {
    await this.router.navigate(['../'], {relativeTo: this.route});
  }

}
