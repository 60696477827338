<div class="container">
  <mat-tab-group dynamicHeight>

    <mat-tab label="Detail">
      <wim-keycloak-user-detail/>
    </mat-tab>

    <mat-tab label="Roles">
      <wim-keycloak-user-roles/>
    </mat-tab>

  </mat-tab-group>

</div>
