import {Component, inject, model, OnInit, signal} from '@angular/core';
import {MatButton, MatIconButton} from '@angular/material/button';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import {MatIcon} from '@angular/material/icon';
import {CantaaErrorHandlerService} from '../../../../service/cantaa-error-handler.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../database/confirm-dialog/confirm-dialog.component';
import {
  CreateDatabaseUserDialogComponent
} from '../../../database-user/create-database-user-dialog/create-database-user-dialog.component';
import {KeycloakUser} from '../../../../model/keycloak-user.model';
import {KeycloakUserService} from '../../../../service/keycloak-user.service';
import {RouteHelper} from '../../../../service/helper/route-helper';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {KeycloakOrganization} from '../../../../model/keycloak-organization.model';
import {KeycloakOrganizationService} from '../../../../service/keycloak-organization.service';

@Component({
  selector: 'wim-keycloak-user-list',
  standalone: true,
  imports: [
    MatButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    ReactiveFormsModule
  ],
  templateUrl: './keycloak-user-list.component.html',
  styleUrl: './keycloak-user-list.component.scss'
})
export class KeycloakUserListComponent implements OnInit {
  private keycloakUserService = inject(KeycloakUserService);
  private keycloakOrganizationService = inject(KeycloakOrganizationService);
  private errorHandler = inject(CantaaErrorHandlerService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private dialog = inject(MatDialog);

  displayedColumns: string[] = ['username', 'firstName', 'lastName', 'email', 'action'];
  keycloakUsers = signal<KeycloakUser[]>([]);

  organizations = signal<KeycloakOrganization[]>([]);
  organizationKeycloakId = model<string | null>('allOrganizations');

  async ngOnInit() {
    await this.fetchOrganizations();
    await this.fetchKeycloakUsers();
  }

  async fetchOrganizations() {
    try {
      const keycloakOrganization = await this.keycloakOrganizationService.getKeycloakOrganizations();
      this.organizations.set(keycloakOrganization);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch organizations');
      throw e;
    }
  }

  async fetchKeycloakUsers() {
    try {
      const organizationId = this.organizationKeycloakId() === 'allOrganizations' ? null : this.organizationKeycloakId();

      const users = await this.keycloakUserService.getKeycloakUsers(organizationId);
      this.keycloakUsers.set(users);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch keycloak users');
      throw e;
    }
  }

  async onEdit(keycloakUser: KeycloakUser) {
    await this.router.navigate([keycloakUser.keycloakId], {relativeTo: this.activatedRoute});
  }

  async onDelete(keycloakUser: KeycloakUser) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {question: 'Are you sure to delete ' + keycloakUser.username + ' ?'},
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.deleteKeycloakUser(keycloakUser.keycloakId);
      }
    });
  }

  async deleteKeycloakUser(userKeycloakId: string) {
    try {
      await this.keycloakUserService.deleteKeycloakUser(userKeycloakId);
      await this.fetchKeycloakUsers();
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to delete database user');
    }
  }

  async openCreateDialog() {
    const dialogRef = this.dialog.open(CreateDatabaseUserDialogComponent);

    dialogRef.afterClosed().subscribe(created => {
      if (created) {
        this.fetchKeycloakUsers();
      }
    });

  }

  async onCreate() {
    await RouteHelper.navigateToNew(this.activatedRoute, this.router);
  }

  async onOrganizationChange() {
    await this.fetchKeycloakUsers();
  }
}
