import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedUserService } from '../../service/logged-user.service';
import { FormsModule } from '@angular/forms';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'wim-main-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatToolbar,
    MatIcon,
    MatIconButton,
    MatButton,
    RouterLink,
    MatTooltip
  ],
  templateUrl: './main-toolbar.component.html',
  styleUrl: './main-toolbar.component.scss'
})
export class MainToolbar implements OnInit {

  @Output()
  sidenavToggled: EventEmitter<any> = new EventEmitter();

  userName = '';
  email = '';

  constructor(private loggedUserService: LoggedUserService,) {
  }

  ngOnInit() {
    this.loggedUserService.loggedUser$
      .subscribe(loggedUser => {
        this.userName = loggedUser.name;
        this.email = loggedUser.email;
      });
  }

  toggle() {
    this.sidenavToggled.emit();
  }
}
