import { Component, OnInit, signal, viewChild } from '@angular/core';
import { KeycloakRole } from '../../../../model/keycloak-role.model';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { RouteHelper } from '../../../../service/helper/route-helper';
import { KeycloakUserService } from '../../../../service/keycloak-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CantaaErrorHandlerService } from '../../../../service/cantaa-error-handler.service';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { CantaaMessageService } from '../../../../service/cantaa-message.service';

@Component({
  selector: 'wim-keycloak-user-roles',
  standalone: true,
  imports: [
    MatSelectionList,
    MatListOption,
    MatButton,
    FormsModule
  ],
  templateUrl: './keycloak-user-roles.component.html',
  styleUrl: './keycloak-user-roles.component.scss'
})
export class KeycloakUserRolesComponent implements OnInit {
  selectionList = viewChild<MatSelectionList>('selectionList');

  roles = signal<KeycloakRole[]>([]);
  userKeycloakId = '';

  constructor(private keycloakUserService: KeycloakUserService,
              private route: ActivatedRoute,
              private errorHandler: CantaaErrorHandlerService,
              private messageService: CantaaMessageService,
              private router: Router) {
  }

  async ngOnInit() {
    this.userKeycloakId = this.route.snapshot.paramMap.get('userKeycloakId')!;
    this.roles.set(await this.fetchKeycloakRoles(this.userKeycloakId));
  }

  private async fetchKeycloakRoles(userKeycloakId: string) {
    try {
      return await this.keycloakUserService.getRolesForUser(userKeycloakId);
    } catch (e) {
      this.errorHandler.handleError(e, 'Failed to fetch keycloak roles');
      throw e;
    }
  }

  async onSave() {
    const selectedValues = this.selectionList()?.selectedOptions.selected.map(option => option.value.name);
    console.log('selectedValues', selectedValues);

    const roles = this.roles().map(role => {
      const assigned = selectedValues!.includes(role.name);
      return {
        ...role,
        assigned
      };
    });
    console.log('new roles', roles);
    await this.assignRolesForUser(this.userKeycloakId, roles);
    this.messageService.success('Roles successfully assigned');
  }

  private async assignRolesForUser(userKeycloakId: string, roles: KeycloakRole[]) {
    try {
      return await this.keycloakUserService.assignRolesForUser(userKeycloakId, roles);
    } catch (e) {
      this.errorHandler.handleError(e, 'Failed to assign roles for user');
      throw e;
    }
  }

  async onCancel() {
    await RouteHelper.navigateUp(this.route, this.router);
  }
}
