import { AuthConfig } from 'angular-oauth2-oidc';

declare const require: any;

const authCodeFlowConfig: AuthConfig = {
  strictDiscoveryDocumentValidation: false,
  issuer: 'https://sso.wim.cantaa.de/realms/wim-main',
  redirectUri: window.location.origin,
  clientId: 'wim-fe',
  responseType: 'code',
  timeoutFactor: 0.7,
  scope: 'openid offline_access',
  showDebugInformation: true,
};

export const environment = {
  environment: 'development',
  production: false,
  appVersion: require('../../package.json').version,
  apiUrl: '/api',
  //   DEBUG, INFO, WARN, ERROR
  logLevel: 'DEBUG',
  testData: true,
  debugMode: false,
  authCodeFlowConfig: authCodeFlowConfig
};
