<h2 mat-dialog-title>Create database</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Database name</mat-label>
    <input matInput [(ngModel)]="databaseName" required/>
  </mat-form-field>
  <div>
    <mat-form-field class="w-100">
      <mat-label>Owner</mat-label>
      <mat-select [(ngModel)]="databaseOwner">
        @for (user of databaseUsers(); track user.name) {
          <mat-option [value]="user.name">{{ user.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onConfirm()" cdkFocusInitial [disabled]="databaseName() && databaseName().trim().length == 0 ">Create</button>
</mat-dialog-actions>
