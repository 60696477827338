import { Component, OnInit, signal } from '@angular/core';
import { DatabaseService } from '../../../service/database.service';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { Database } from '../../../model/database.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateDatabaseDialogComponent } from '../create-database-dialog/create-database-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ChangeDbNameDialogComponent } from '../change-db-name-dialog/change-db-name-dialog.component';

@Component({
  selector: 'wim-database-list',
  standalone: true,
  imports: [
    MatTable,
    MatHeaderRow,
    MatRow,
    MatCell,
    MatHeaderCell,
    MatColumnDef,
    MatCellDef,
    MatRowDef,
    MatHeaderRowDef,
    MatHeaderCellDef,
    MatIcon,
    MatIconButton,
    MatButton
  ],
  templateUrl: './database-list.component.html',
  styleUrl: './database-list.component.scss'
})
export class DatabaseListComponent implements OnInit {

  displayedColumns: string[] = ['database', 'action'];
  databases = signal<Database[]>([]);

  constructor(private databaseService: DatabaseService,
              private errorHandler: CantaaErrorHandlerService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog) {
  }

  async ngOnInit() {
    await this.fetchDatabases();
  }

  async fetchDatabases() {
    try {
      const databases = await this.databaseService.getDatabases();
      this.databases.set(databases);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch databases');
      throw e;
    }
  }

  async onEdit(database: Database) {
    await this.router.navigate([database.name], { relativeTo: this.activatedRoute });
  }

  async onDelete(database: Database) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { question: 'Are you sure to delete ' + database.name + ' ?' },
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.deleteDatabase(database.name);
      }
    });
  }

  async deleteDatabase(databaseName: string) {
    try {
      await this.databaseService.deleteDatabase(databaseName);
      await this.fetchDatabases();
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to delete database');
    }
  }

  async openCreateDialog() {
    const dialogRef = this.dialog.open(CreateDatabaseDialogComponent);

    dialogRef.afterClosed().subscribe(created => {
      if (created) {
        this.fetchDatabases();
      }
    });
  }

  async onRename(database: Database) {
    const dialogRef = this.dialog.open(ChangeDbNameDialogComponent, {
      data: { databaseName: database.name },
    });

    dialogRef.afterClosed().subscribe(created => {
      if (created) {
        this.fetchDatabases();
      }
    });

  }
}
