import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StageInfoResponse } from '../model/stage-info-response.model';
import { firstValueFrom } from 'rxjs';
import { StageInfo } from '../model/stage-detail.model';
import { ConsoleLoggerService } from './console-logger.service';


@Injectable({
  providedIn: 'root'
})
export class StageInfoService {

  readonly INFO_ENDPOINT = '/api/app-info';

  private _stages: StageInfo[] = [
    { name: 'Wim Develop', stage: 'DEV', url: 'https://wim-develop.cantaa.de' },
    { name: 'Wim Main', stage: 'MAIN', url: 'https://wim-main.cantaa.de' }
  ];

  constructor(private http: HttpClient, private log: ConsoleLoggerService) {
  }

  getStageDetails() {
    let requests = this._stages.map(async stageInfo => {
      return await this.getStageDetail(stageInfo);
    });
    return Promise.all(requests);
  }

  async getStageDetail(stageInfo: StageInfo) {
    const stageInfoResponse = await this.fetchStageDetail(stageInfo);

    return {
      ...stageInfo,
      version: stageInfoResponse?.version,
      appType: stageInfoResponse?.appType,
      meta: stageInfoResponse?.meta
    } as StageInfo;

  }

  private async fetchStageDetail(stageInfo: StageInfo) {
    try {
      const response$ = this.http.get<StageInfoResponse>(stageInfo.url + this.INFO_ENDPOINT);
      return await firstValueFrom(response$);
    } catch (e) {
      this.log.error('Failed to fetch stage info', e);
      return null;
    }
  }


  get stages(): StageInfo[] {
    return this._stages;
  }
}
