<div class="container p-3">
  <form [formGroup]="detailForm" (ngSubmit)="onSave()">

    <input hidden formControlName="keycloakId">

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Organization name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Domains</mat-label>
          <input matInput formControlName="domains">
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Tenant</mat-label>
          <input matInput formControlName="tenant">
        </mat-form-field>
      </div>

    </div>

    <div class="row">
      <div class="col-9">
        <mat-checkbox formControlName="enabled" color='primary'>
          Enabled
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <button type="submit" mat-raised-button color="primary" [disabled]="!detailForm.valid">
          Save
        </button>
        <button type="button" mat-raised-button class="ms-2" (click)="onCancel()">
          Cancel
        </button>
      </div>
    </div>

  </form>

</div>
