import {Component, inject, model, OnInit, signal} from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { DatabaseService } from '../../../service/database.service';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { DatabaseUserService } from '../../../service/database-user.service';
import { DatabaseUser } from '../../../model/database-user.model';
import { MatOption, MatSelect } from '@angular/material/select';

@Component({
  selector: 'wim-create-database-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatInput,
    FormsModule,
    MatDialogClose,
    MatDialogActions,
    MatButton,
    MatInputModule,
    MatSelect,
    MatOption
  ],
  templateUrl: './create-database-dialog.component.html',
  styleUrl: './create-database-dialog.component.scss'
})
export class CreateDatabaseDialogComponent implements OnInit {


  readonly dialogRef = inject(MatDialogRef<CreateDatabaseDialogComponent>);
  readonly databaseService = inject(DatabaseService);
  readonly errorHandler = inject(CantaaErrorHandlerService);
  readonly databaseUserService = inject(DatabaseUserService);

  readonly databaseName = model('');
  readonly databaseOwner = model('');
  databaseUsers = signal<DatabaseUser[]>([]);

  async ngOnInit() {
     await this.fetchDatabaseUsers();
  }

  async fetchDatabaseUsers() {
    try {
      const databaseUsers = await this.databaseUserService.getDatabaseUsers();
      this.databaseUsers.set(databaseUsers);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch database users');
      throw e;
    }
  }

  async onConfirm() {
    try {
      const database = {
        name: this.databaseName(),
        owner: this.databaseOwner(),
      }
      await this.databaseService.createDatabase(database);
      this.dialogRef.close(true);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to create database: ' + this.databaseName());
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
