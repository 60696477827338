import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom, map, tap } from 'rxjs';
import { CollectionDto } from '../model/collection.model';
import { ConsoleLoggerService } from './console-logger.service';
import { KeycloakUser } from '../model/keycloak-user.model';
import { KeycloakRole } from '../model/keycloak-role.model';


@Injectable({
  providedIn: 'root'
})
export class KeycloakUserService {

  constructor(private http: HttpClient, private log: ConsoleLoggerService) {
  }

  getKeycloakUsers(organizationKeycloakId: string | null) {
    let params = new HttpParams();

    if (organizationKeycloakId) {
      params = params.append('organizationKeycloakId', organizationKeycloakId);
    }

    const url = environment.apiUrl + `/keycloak/users`;
    const users = this.http.get<CollectionDto<KeycloakUser>>(url, { params: params })
      .pipe(tap(r => this.log.debug('keycloak users: ', r)))
      .pipe(map(c => c.items))
    return firstValueFrom(users);
  }

  getKeycloakUser(userKeycloakId: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/users/${userKeycloakId}`;
    const user$ = this.http.get<KeycloakUser>(url, { params: params });
    return firstValueFrom(user$);
  }

  isUserNameTaken(userKeycloakId: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/users/takenUsername/${userKeycloakId}`;
    return  this.http.get<boolean>(url, { params: params });
  }

  isEmailTaken(userKeycloakId: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/users/takenEmail/${userKeycloakId}`;
    return  this.http.get<boolean>(url, { params: params });
  }

  saveKeycloakUser(user: KeycloakUser) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/users`;
    const database$ = this.http.post<KeycloakUser>(url, user, { params: params });
    return firstValueFrom(database$);
  }

  deleteKeycloakUser(userKeycloakId: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/users/${userKeycloakId}`;
    const database$ = this.http.delete<void>(url, { params: params });
    return firstValueFrom(database$);
  }

  getRolesForUser(userKeycloakId: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/roles/${userKeycloakId}`;
    const users = this.http.get<CollectionDto<KeycloakRole>>(url, { params: params })
      .pipe(tap(r => this.log.debug('keycloak roles: ', r)))
      .pipe(map(c => c.items))
    return firstValueFrom(users);
  }

  assignRolesForUser(userKeycloakId: string, roles: KeycloakRole[]) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/roles/${userKeycloakId}/assign`;
    const call$ = this.http.put<void>(url, { items: roles }, { params: params })
    return firstValueFrom(call$);
  }

}
