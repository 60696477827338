import {inject, Injectable} from '@angular/core';
import {AbstractControl, AsyncValidator, ValidationErrors} from '@angular/forms';
import {catchError, map, Observable, of} from 'rxjs';
import {KeycloakUserService} from "../service/keycloak-user.service";

@Injectable({providedIn: 'root'})
export class UniqueKeycloakEmailValidator implements AsyncValidator {

  keycloakUserService = inject(KeycloakUserService);

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.keycloakUserService.isEmailTaken(control.value).pipe(
      map((isTaken) => (isTaken ? {uniqueEmail: true} : null)),
      catchError(() => of(null)),
    );
  }

}
