<div class="container p-3">
  <form [formGroup]="detailForm" (ngSubmit)="onSave()">

    <input hidden formControlName="keycloakId">

    <div class="row">

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>User name</mat-label>
          <input matInput formControlName="username">
        </mat-form-field>
      </div>

    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstName">
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastName">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>email</mat-label>
          <input matInput formControlName="email">
        </mat-form-field>
      </div>

    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Organization</mat-label>
          <mat-select formControlName="organization">
            <mat-option [value]="null"></mat-option>
            @for (organization of organizations(); track organization.keycloakId) {
              <mat-option [value]="organization">{{ organization.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

      </div>
    </div>

    <div class="row">
      <div class="col-9">
        <mat-checkbox formControlName="enabled" color='primary'>
          Enabled
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-5">
        <mat-form-field class="w-100">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <button type="submit" mat-raised-button color="primary" [disabled]="!detailForm.valid">
          Save
        </button>
        <button type="button" mat-raised-button class="ms-2" (click)="onCancel()">
          Cancel
        </button>
      </div>
    </div>

  </form>

</div>
