<h2 mat-dialog-title>Rename database</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Database name</mat-label>
    <input matInput [(ngModel)]="newDatabaseName" required/>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Cancel</button>
  <button mat-button (click)="onConfirm()" [disabled]="renameDisabled()">Rename</button>
</mat-dialog-actions>
