<div class="container">

  <div>
    <button mat-icon-button (click)="fetchData()" class="mb-3">
      <mat-icon>refresh</mat-icon>
    </button>

    <table mat-table [dataSource]="stages" class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef> Url</th>
        <td mat-cell *matCellDef="let element">
          {{ element.url }}

          <a mat-icon-button [href]="element.url" target="_blank">
            <mat-icon>open_in_new</mat-icon>
          </a>

        </td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef> Version</th>
        <td mat-cell *matCellDef="let element" title="{{ JSON.stringify(element.meta) }}"> {{ element.version }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>

</div>

