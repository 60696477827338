import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom, map, tap } from 'rxjs';
import { CollectionDto } from '../model/collection.model';
import { ConsoleLoggerService } from './console-logger.service';
import { KeycloakOrganization } from '../model/keycloak-organization.model';


@Injectable({
  providedIn: 'root'
})
export class KeycloakOrganizationService {

  constructor(private http: HttpClient, private log: ConsoleLoggerService) {
  }

  getKeycloakOrganizations() {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/organizations`;
    const organizations$ = this.http.get<CollectionDto<KeycloakOrganization>>(url, { params: params })
      .pipe(tap(r => this.log.debug('keycloak organizations: ', r)))
      .pipe(map(c => c.items))
    return firstValueFrom(organizations$);
  }

  getKeycloakOrganization(keycloakOrganizationId: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/organizations/${keycloakOrganizationId}`;
    const organization$ = this.http.get<KeycloakOrganization>(url, { params: params });
    return firstValueFrom(organization$);
  }

  isOrganizationTaken(organizationId: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/organizations/takenOrganization/${organizationId}`;
    return  this.http.get<boolean>(url, { params: params });
  }

  save(keycloakOrganization: KeycloakOrganization) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/organizations`;
    const organization$ = this.http.post(url, keycloakOrganization, { params: params, responseType: 'text'});
    return firstValueFrom(organization$);
  }

  deleteKeycloakOrganization(keycloakOrganizationId: string) {
    const params = new HttpParams();
    const url = environment.apiUrl + `/keycloak/organizations/${keycloakOrganizationId}`;
    const observable$ = this.http.delete<void>(url, { params: params });
    return firstValueFrom(observable$);
  }

}
