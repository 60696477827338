import { Component, OnInit, signal } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef, MatTable
} from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { Database } from '../../../model/database.model';
import { DatabaseService } from '../../../service/database.service';
import { CantaaErrorHandlerService } from '../../../service/cantaa-error-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../database/confirm-dialog/confirm-dialog.component';
import { CreateDatabaseDialogComponent } from '../../database/create-database-dialog/create-database-dialog.component';
import { DatabaseUser } from '../../../model/database-user.model';
import { DatabaseUserService } from '../../../service/database-user.service';
import { CreateDatabaseUserDialogComponent } from '../create-database-user-dialog/create-database-user-dialog.component';

@Component({
  selector: 'wim-database-user-list',
  standalone: true,
    imports: [
      MatTable,
      MatHeaderRow,
      MatRow,
      MatCell,
      MatHeaderCell,
      MatColumnDef,
      MatCellDef,
      MatRowDef,
      MatHeaderRowDef,
      MatHeaderCellDef,
      MatIcon,
      MatIconButton,
      MatButton
    ],
  templateUrl: './database-user-list.component.html',
  styleUrl: './database-user-list.component.scss'
})
export class DatabaseUserListComponent implements OnInit {

  displayedColumns: string[] = ['database', 'action'];
  databaseUsers = signal<DatabaseUser[]>([]);

  constructor(private databaseUserService: DatabaseUserService,
              private errorHandler: CantaaErrorHandlerService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog) {
  }

  async ngOnInit() {
    await this.fetchDatabaseUsers();
  }

  async fetchDatabaseUsers() {
    try {
      const databaseUsers = await this.databaseUserService.getDatabaseUsers();
      this.databaseUsers.set(databaseUsers);
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to fetch database users');
      throw e;
    }
  }

  async onEdit(databaseUser: DatabaseUser) {
    await this.router.navigate([databaseUser.name], { relativeTo: this.activatedRoute });
  }

  async onDelete(databaseUser: DatabaseUser) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { question: 'Are you sure to delete ' + databaseUser.name + ' ?' },
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.deleteDatabaseUser(databaseUser.name);
      }
    });
  }

  async deleteDatabaseUser(userName: string) {
    try {
      await this.databaseUserService.deleteDatabaseUser(userName);
      await this.fetchDatabaseUsers();
    } catch (e) {
      this.errorHandler.handleError(e, 'Unable to delete database user');
    }
  }

  async openCreateDialog() {
    const dialogRef = this.dialog.open(CreateDatabaseUserDialogComponent);

    dialogRef.afterClosed().subscribe(created => {
      if (created) {
        this.fetchDatabaseUsers();
      }
    });

  }
}
