<h2 mat-dialog-title>Create database user</h2>
<mat-dialog-content>
  <div>
    <mat-form-field>
      <mat-label>User name</mat-label>
      <input matInput [(ngModel)]="databaseUserName" required/>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>User password</mat-label>
      <input matInput [(ngModel)]="databaseUserPassword" required/>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onConfirm()" cdkFocusInitial [disabled]="databaseUserName() && databaseUserName().trim().length == 0 ">
    Create
  </button>
</mat-dialog-actions>
